<template>
  <div class="not-authorized">
    <h1>Not Authorized</h1>
    <p>You need to log in to access this page.</p>
    <button class="btn btn-primary" @click="login">Log In</button>
  </div>
</template>

<script>

export default {
  name: 'NotAuthorizedView',
  methods: {
    login() {
      const redirectPath = this.$route.query.redirect;
      if (redirectPath) {
        this.$router.push({name: 'Login', query: {redirect: redirectPath}});
      } else {
        this.$router.push({name: 'Login'});
      }
    },
  },
}
</script>

<style scoped>
.not-authorized {
  text-align: center;
  margin-top: 50px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

.btn {
  padding: 10px 20px;
  font-size: 16px;
}
</style>