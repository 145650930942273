<template>
  <div class="container">
    <div class="profile-header"><h1>User Profile</h1></div>
    <div class="profile-content">
      <div class="row">
        <div class="col-md-8">
          <div class="profile-info"><h3>Hi, {{ user.signInDetails.loginId }} 👋</h3>
            <p><strong>User ID:</strong> {{ user.userId }}</p>
            <div class="profile-actions">
              <button class="btn btn-info change-password-btn" @click="changePassword">Change Password</button>
              <button class="btn btn-danger logout-btn" @click="logout">Log Out</button>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <div class="user-metadata"><h3>User Metadata</h3>
            <pre>{{ user }}</pre>
          </div>
        </div>
      </div>
      <div class="jwt-tokens"><h2>JWT Tokens</h2>
        <button class="btn btn-secondary token-btn" @click="toggleJwtTokens">
          {{ showJwtTokens ? 'Hide JWT Tokens' : 'Show JWT Tokens' }}
        </button>
        <div v-if="showJwtTokens">
          <div v-if="idToken" class="token-section"><h3>ID Token</h3>
            <pre>{{ idToken }}</pre>
          </div>
          <div v-if="accessToken" class="token-section"><h3>Access Token</h3>
            <pre>{{ accessToken }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script> import {mapActions, mapState} from "vuex";

export default {
  data() {
    return {showJwtTokens: false,};
  }, computed: {
    ...mapState('auth', ['idToken', 'accessToken']), user() {
      return this.$store.state.auth.user;
    },
  }, methods: {
    ...mapActions('auth', ['logout', 'fetchAuthKeys']), toggleJwtTokens() {
      if (!this.showJwtTokens) {
        this.fetchAuthKeys();
      }
      this.showJwtTokens = !this.showJwtTokens;
    }, async logout() {
      this.$router.push('/');
      this.$store.dispatch('auth/logout');
    }, changePassword() {
      this.$router.push({name: 'ChangePassword'});
    },
  },
}; </script>
<style scoped> .profile-header {
  text-align: center;
  margin-bottom: 30px;
}

.profile-content {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-info {
  margin-bottom: 30px;
}

.profile-actions {
  margin-top: 20px;
}

.btn {
  margin-right: 10px;
}

.user-metadata {
  margin-top: 30px;
}

.user-metadata h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.jwt-tokens {
  margin-top: 30px;
}

.token-btn {
  margin-bottom: 20px;
}

.token-section {
  margin-bottom: 20px;
}

.token-section h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

pre {
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
} </style>