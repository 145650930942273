<template>
  <div>
    <div v-for="error in errors" :key="error" class="alert alert-danger" type="alert">
      {{ error }}
    </div>
    <!--    <section>-->
    <!--      <form @submit.prevent="checkForm">-->
    <!--        <div class="mb-3">-->
    <!--          <label class="form-label" for="tag_id">Tag ID:</label>-->
    <!--          <input v-model="form.tag_id" class="form-control" name="tag_id" type="text"/>-->
    <!--        </div>-->
    <!--        <div class="mb-3">-->
    <!--          <label class="form-label" for="name">Name:</label>-->
    <!--          <input v-model="form.name" class="form-control" name="name" type="text"/>-->
    <!--        </div>-->
    <!--        <div class="mb-3">-->
    <!--          <label class="form-label" for="desc">Description:</label>-->
    <!--          <textarea-->
    <!--              v-model="form.desc"-->
    <!--              class="form-control"-->
    <!--              name="desc"-->
    <!--          ></textarea>-->
    <!--        </div>-->
    <!--        <div class="mb-3">-->
    <!--          <label class="form-check-label" for="enable_link_url">Redirected link?</label>-->
    <!--          <input-->
    <!--              v-model="form.enable_link_url"-->
    <!--              class="form-check-input"-->
    <!--              name="enable_link_url"-->
    <!--              type="checkbox"-->
    <!--          />-->
    <!--        </div>-->
    <!--        <div v-if="form.enable_link_url" class="mb-3">-->
    <!--          <label class="form-label" for="link_url">Redirect Link:</label>-->
    <!--          <textarea-->
    <!--              v-if="form.enable_link_url"-->
    <!--              v-model="form.link_url"-->
    <!--              class="form-control"-->
    <!--              name="link_url"-->
    <!--              placeholder="https://example.com" required="required"-->
    <!--          ></textarea>-->
    <!--        </div>-->
    <!--        <button class="btn btn-primary" type="submit">Submit</button>-->
    <!--      </form>-->
    <!--    </section>-->

    <section>
      <h1>Tags</h1>
      <hr/>

      <router-link :to="{name: 'TagNew'}" class="btn btn-primary w-100 mb-3">Create New Tag</router-link>
      <br/>

      <div v-if="tags != null && tags.length > 0">

        <table class="table table-striped table-hover ">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Tag</th>
            <th scope="col">Name</th>
            <th scope="col">Redirect</th>
          </tr>
          </thead>
          <tbody class="table-group-divider">
          <tr v-for="tag in tags" :key="tag.id" @click="goToTagDetails(tag.tag_id)">
            <th scope="row">{{ tag.id }}</th>
            <td>{{ tag.tag_id }}</td>
            <td>{{ tag.name }}</td>
            <td>{{ tag.redirect_flag }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div v-else-if="tags != null">
        <div class="alert alert-danger" type="alert">
          No Tags Found.
        </div>
      </div>

      <div v-else>
        <div class="alert alert-warning" type="alert">
          Loading...
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {mapActions, mapGetters} from 'vuex';

export default defineComponent({
  name: 'Dashboard-View',
  data() {
    return {
      form: {
        title: '',
        content: '',
      },
      errors: [],
    };
  },
  created: function () {
    return this.$store.dispatch('getTags')
        .catch((err) => {
          console.log(err);
          this.errors.push("Failed to load all tags.")
          this.$store.commit('setTags', [])
        });
  },
  computed: {
    ...mapGetters({tags: 'stateTags'}),
  },
  methods: {
    ...mapActions(['createTag']),
    async checkForm(e) {
      e.preventDefault();
      //eslint-disable-next-line no-useless-escape
      let url_regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&\/=]*$/;
      this.errors = [];

      if (this.form.enable_link_url) {
        if (!url_regex.test(this.form.link_url)) {
          console.log('Invalid URL provided.');
          this.errors.push('Invalid URL provided.');
          return;
        }
      }
      await this.submitNewTagForm();
    },
    async submitNewTagForm() {
      let tag = {
        tag_id: this.form.tag_id,
        name: this.form.name,
        desc: this.form.desc,
        redirect_flag: this.form.enable_link_url,
        redirect_to: this.form.link_url,
      };
      await this.createTag(tag)
          .then(() => {
            this.$router.push({path: `tags_details/${tag.tag_id}`});
          })
          .catch((err) => {
            if (err.response) {
              console.log("Response Error: " + err);
              console.log(err.response.data);
              console.log(err.response.status);
              this.errors.push("Create Tag was unsuccessful. Code: " + err.response.status);
            } else if (err.request) {
              console.log("Request Error: " + err);
              this.errors.push("Failed to make request to API");
            } else {
              console.log("Other Error: " + err);
              this.errors.push(err);
            }
          });
    },
    async goToTagDetails(id) {
      console.log("Going to tag details for: " + id)
      this.$router.push({name: 'TagsDetails', params: {id: id}});
    },
  }
});
</script>
