<template>
  <div class="upload-component">
    <div v-if="errors.length > 0" class="alert alert-danger">
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </div>

    <div class="card">
      <div class="card-body">
        <!--        <h5 class="card-title">File Upload</h5>-->

        <!-- Expiration Panel -->
        <div class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="mb-0">
              <i class="bi bi-calendar-event"></i> Expiration Date/Time
            </h6>
            <button class="btn btn-sm btn-primary" @click="showExpirationPanel = !showExpirationPanel">
              {{ showExpirationPanel ? 'Hide' : 'Show' }}
            </button>
          </div>
          <div v-if="showExpirationPanel" class="mt-2">
            <VueDatePicker
                id="expiration-date"
                v-model="expirationDate"
                :disabled="isUploading"
                class="form-control"
                :default-value="this.defaultExpirationDate"
                disable-year-select
                auto-apply
                :max-date="maxExpirationDate"
                :min-date="new Date()"
            ></VueDatePicker>

            <!-- Time till expiration -->
            <div class="alert alert-info mt-2">
              <i class="bi bi-clock"></i> File will be deleted in {{ countdownMessage }}
            </div>
          </div>
        </div>

        <!-- Drag and Drop Area -->
        <div class="file-upload mb-3 p-4 border rounded" @dragover.prevent @drop.prevent="onDrop">
          <input id="file" ref="file" class="form-control-file" type="file" @change="onFileChange()"
                 :disabled="isUploading">
          <label for="file" class="file-label text-center w-100" :class="{ 'dragging': isDragging }">
            <i class="bi bi-upload display-4 mb-2"></i>
            <p class="mb-0">{{ isDragging ? 'Release to drop files' : 'Drag and drop files or click to select' }}</p>
          </label>
        </div>

        <!-- Selected File -->
        <div v-if="selectedFile"
             class="selected-file mb-3 d-flex justify-content-between align-items-center p-3 border rounded">
          <div class="file-info">
            <span class="file-name">{{ selectedFile.name }}</span>
            <span class="file-size">{{ formatFileSize(selectedFile.size) }}</span>
          </div>
          <button class="btn btn-sm btn-danger" @click="clearSelectedFile" :disabled="isUploading">
            <i class="bi bi-x"></i> Clear
          </button>
        </div>

        <!-- Progress Bar -->
        <div v-if="isUploading" class="progress mb-3">
          <div class="progress-bar" role="progressbar" :style="{ width: uploadProgress.percentage + '%' }"
               :aria-valuenow="uploadProgress.percentage" aria-valuemin="0" aria-valuemax="100">
            {{ uploadProgress.percentage.toFixed(2) }}%
          </div>
        </div>

        <!-- Cancel Upload Button -->
        <div v-if="isUploading" class="mb-3">
          <button class="btn btn-danger" @click="cancelUpload" :disabled="!isUploading">
            <i class="bi bi-x-circle"></i> Cancel Upload
          </button>
        </div>
      </div>
    </div>

    <!-- Upload Success Alert -->
    <div v-if="uploadSuccess" class="alert alert-success mt-3">
      <div class="success-message">
        <i class="bi bi-check-circle-fill"></i> File uploaded successfully!
      </div>
      <div class="redirect-message">
        Redirecting to file details in {{ redirectCountdown }} seconds...
      </div>
      <div class="progress redirect-progress">
        <div class="progress-bar" role="progressbar" :style="{ width: redirectProgress + '%' }"
             :aria-valuenow="redirectProgress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios';
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  components: {
    VueDatePicker,
  },
  data() {
    return {
      errors: [],
      selectedFile: null,
      isDragging: false,
      isUploading: false,
      uploadSuccess: false,
      cancelTokenSource: null,
      uploadedFileId: null,
      redirectCountdown: 3,
      redirectProgress: 0,
      redirectTimer: null,
      expirationDate: null,
      showExpirationPanel: false,
    };
  },
  computed: {
    ...mapState('files', ['chunkProgressList']),
    ...mapGetters('files', ['stateUploadProgress']),
    uploadProgress() {
      return this.stateUploadProgress;
    },
    defaultExpirationDate() {
      const now = new Date();
      now.setHours(now.getHours() + 1);
      return now;
    },
    maxExpirationDate() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7, 23, 59);
    },
    countdownMessage() {
      const expirationDiff = this.expirationDate - new Date();
      const days = Math.floor(expirationDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((expirationDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((expirationDiff % (1000 * 60 * 60)) / (1000 * 60));

      if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''}, ${hours} hour${hours > 1 ? 's' : ''}`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${minutes > 1 ? 's' : ''}`;
      } else {
        return `${minutes} minute${minutes > 1 ? 's' : ''}`;
      }
    },
  },
  watch: {
    selectedFile(newFile) {
      if (newFile) {
        this.uploadFile();
      }
    },
  },
  methods: {
    ...mapActions('files', ['uploadFile']),

    onFileChange() {
      const file = this.$refs.file.files[0];
      this.handleFileSelection(file);
    },

    onDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const file = event.dataTransfer.files[0];
      this.handleFileSelection(file);
    },

    handleFileSelection(file) {
      this.selectedFile = file;
      this.errors = [];
      this.uploadSuccess = false;
    },

    clearSelectedFile() {
      this.selectedFile = null;
      this.$refs.file.value = '';
    },

    async uploadFile() {
      if (this.selectedFile) {
        this.isUploading = true;
        this.uploadSuccess = false;
        this.showExpirationPanel = false;
        this.errors = [];

        try {
          const onUploadProgress = (progressEvent, part) => {
            if (part) {
              this.$store.commit('files/setChunkUploadProgress', {
                part: part.PartNumber,
                progress: parseInt((progressEvent.loaded) * 100 / progressEvent.total)
              });
            } else {
              this.$store.commit('files/updateUploadProgress', progressEvent.loaded);
            }
          };

          this.cancelTokenSource = axios.CancelToken.source();

          const fileId = await this.$store.dispatch('files/uploadFile', {
            file: this.selectedFile,
            onUploadProgress,
            expires: this.expirationDate,
            cancelToken: this.cancelTokenSource.token,
          });

          if (fileId) {
            this.uploadSuccess = true;
            this.uploadedFileId = fileId;
            this.selectedFile = null;
            this.$refs.file.value = '';

            this.startRedirectCountdown();
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Upload canceled');
          } else {
            this.errors.push('An error occurred during file upload.');
            console.error(error);
          }
        } finally {
          this.isUploading = false;
          this.cancelTokenSource = null;
        }
      }
    },

    cancelUpload() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Upload canceled by the user.');
      }
    },

    formatFileSize(size) {
      const units = ['B', 'KB', 'MB', 'GB'];
      let index = 0;
      while (size >= 1024 && index < units.length - 1) {
        size /= 1024;
        index++;
      }
      return `${size.toFixed(2)} ${units[index]}`;
    },

    startRedirectCountdown() {
      this.redirectCountdown = 3;
      this.redirectProgress = 0;

      this.redirectTimer = setInterval(() => {
        this.redirectCountdown--;
        this.redirectProgress = (3 - this.redirectCountdown) * 33.33;

        if (this.redirectCountdown <= 0) {
          clearInterval(this.redirectTimer);
          this.redirectToFileDetails();
        }
      }, 1000);
    },

    redirectToFileDetails() {
      if (this.uploadedFileId) {
        this.$router.push({name: 'FileDetails', params: {id: this.uploadedFileId}});
      }
    },
  },

  beforeUnmount() {
    clearInterval(this.redirectTimer);
  },
  mounted() {
    this.expirationDate = this.defaultExpirationDate;
  }
};
</script>

<style scoped>
.upload-component {
  max-width: 500px;
  margin: 0 auto;
}

.card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.file-upload {
  position: relative;
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s;
}

.file-upload:hover {
  border-color: #999;
}

.file-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-label {
  font-size: 18px;
  color: #666;
}

.selected-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.file-info {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-weight: bold;
}

.file-size {
  font-size: 14px;
  color: #666;
}

.progress {
  height: 20px;
  border-radius: 8px;
  background-color: #e9ecef;
}

.progress-bar {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  text-align: center;
  transition: width 0.6s ease;
}

.alert {
  margin-bottom: 20px;
  border-radius: 8px;
}

.btn {
  border-radius: 8px;
  font-weight: bold;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.success-message {
  font-size: 18px;
  color: #155724;
  margin-bottom: 10px;
}

.redirect-message {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.redirect-progress {
  height: 10px;
  background-color: #e9ecef;
  border-radius: 8px;
  margin-bottom: 10px;
}

.redirect-progress .progress-bar {
  background-color: #28a745;
}
</style>