<template>
  <div>
    <section>
      <div v-for="error in errors" :key="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <!--      <div class="alert alert-danger" role="alert">Tag not found</div>-->

      <div v-if="tag != null">
        <h1>{{ tag.name }} ({{ tag.tag_id }})</h1>
      </div>
      <div v-else>
        <h1>Tag Details</h1>
      </div>
      <hr/>

      <div v-if="tag != null">
        <div class="container">
          <div v-if="tag.redirect_flag" class="col-md-6">
            <p>Redirects to: <a :href="tag.redirect_to">{{ tag.redirect_to }}</a></p>
            <p id="redirectCountDownLabel">You will be redirected soon {{ timeLeftBeforeRedirectSeconds }}s</p>
            <button class="btn btn-primary" v-on:click="redirectToTag">Redirect Now</button>
            <button id="redirectBtn" class="btn btn-secondary ms-3" @click="stopRedirectTimer">Cancel</button>
          </div>
          <div>
            <div class="card mt-4">
              <div class="card-header">
                <h2 class="card-title">Tag Details - {{ this.id }}</h2>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <img v-if="tag.img" :src="tag.img" alt="Tag Image" class="img-fluid">
                    <img v-else alt="Tag Image" class="img-fluid" src="https://via.placeholder.com/300">
                  </div>
                  <div class="col-md-6">
                    <h3 class="card-title">{{ tag.name }}</h3>
                    <p class="card-text">{{ tag.desc }}</p>
                    <p class="card-text">Owned by: {{ tag.owner_id }}</p>
                    <p class="card-text">Created at: {{ tag.created }}</p>
                    <p class="card-text">Last Modified at: {{ tag.last_mod }}</p>
                    <p v-if="tag.redirect_flag" class="card-text">
                      Redirects to: <a :href="tag.redirect_to">{{ tag.redirect_to }}</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-footer text-muted">
                Tag ID: {{ tag.tag_id }}
              </div>
            </div>
          </div>

        </div>
      </div>

      <div v-else-if="loadingFromApi">
        <div class="alert alert-info" role="alert">Loading...</div>
      </div>

      <div v-else-if="!loadingFromApi">
        <div class="alert alert-danger" role="alert">
          Tag ID <code>{{ this.id }}</code> not found
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ConfigVars from '@/consts';
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
  name: 'Tag-Details-View',
  data() {
    return {
      form: {
        title: '',
        content: '',
      },
      id: this.$route.params.id,
      timeLeftBeforeRedirectSeconds: ConfigVars.mSecBeforeRedirect / 1000,
      errors: [],
      loadingFromApi: true,
      redirectTimer: null,
    };
  },
  created: function () {
    var vm = this;

    this.$store.dispatch('viewTag', this.$route.params.id)
        .then(() => {
          if (this.tag != null && this.tag.redirect_flag) {
            console.log("Tag contains a Redirect Link - " + this.tag.redirect_to);

            vm.redirectTimer = new Timer(function () {
              vm.redirectToTag();
            }, ConfigVars.mSecBeforeRedirect);

            setInterval(function () {
              console.log("interval called: " + vm.redirectTimer.getTimeLeft());
              vm.timeLeftBeforeRedirectSeconds = Math.floor(vm.redirectTimer.getTimeLeft() / 1000);
            }, 500);
          }

          if (this.tag == null) {
            console.log("Tag is null.");
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("Response Error: " + err);
            console.log(err.response.data);
            console.log(err.response.status);
          } else if (err.request) {
            console.log("Request Error: " + err);
            this.errors.push("Failed to make request to API");
          } else {
            console.log("Other Error: " + err);
            this.errors.push(err);
          }
        })
        .finally(() => {
          this.loadingFromApi = false;
        });

    function Timer(callback, delay) {
      var id, started, remaining = delay, running

      this.start = function () {
        running = true
        started = new Date()
        id = setTimeout(callback, remaining)
      }

      this.pause = function () {
        running = false
        clearTimeout(id)
        remaining -= new Date() - started
      }

      this.getTimeLeft = function () {
        if (running) {
          this.pause()
          this.start()
        }

        return remaining
      }

      this.getStateRunning = function () {
        return running
      }

      this.start()
    }

    return null;
  },
  computed: {
    ...mapGetters({tag: 'stateTag'}),
  },
  methods: {
    redirectToTag() {
      window.location.href = this.tag.redirect_to;
    },
    stopRedirectTimer() {
      this.redirectTimer.pause();
      let redirectBtn = document.getElementById("redirectBtn");
      redirectBtn.hidden = true;
      let redirectCountDownLabel = document.getElementById("redirectCountDownLabel");
      redirectCountDownLabel.hidden = true;
    },
  },
});

</script>
