<template>
  <div id="app">
    <NavBar/>
    <div class="main container">
      <router-view/>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
import AppFooter from "@/components/Footer.vue";

export default {
  components: {
    AppFooter,
    NavBar
  },
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  padding-top: 5%;
  padding-bottom: 5%;
  flex-grow: 1; /* Ensures that this element takes up all available space */
}

footer {
  margin-top: auto; /* Pushes the footer to the bottom */
  flex-shrink: 0; /* Prevents the footer from shrinking */

}
</style>
