<template>
  <div class="about mb-3">
    <h1>Linker.To</h1>
    <h2>You're shortcut to the web!</h2>
    <hr>

    <h2>Credits</h2>
    <p>Icons by <a href="https://icons8.com" target="_blank">Icons8</a></p>
  </div>
</template>
<script setup>
</script>