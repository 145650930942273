import {createStore} from "vuex";

import auth from "@/store/modules/auth";
import tags from './modules/tags';
import users from './modules/users';
import files from './modules/files';


export default createStore({
    modules: {
        auth,
        tags,
        users,
        files,
    }
});
