import {createApp} from "vue";
import axios from 'axios';

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './App.vue';
import router from './router';
import store from './store';
import Vue3Toastify from 'vue3-toastify';
import {Amplify} from 'aws-amplify';
import {getCurrentUser} from 'aws-amplify/auth';
import {sessionStorage} from 'aws-amplify/utils';
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito'

const app = createApp(App);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;  // the FastAPI backend

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID,
            userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        }
    }
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

async function isAuthenticated_background_at_main_load() {
    try {
        const user = await getCurrentUser();
        console.log('at page load a user was found');
        // eslint-disable-next-line
        await store.dispatch('auth/fetchSession', user);
    } catch (error) {
        //  console.error(error);
        await store.dispatch('auth/fetchSession', null);
    }
}

isAuthenticated_background_at_main_load().then(() => console.log('isAuthenticated_background_at_main_load done'));

app.use(router);
app.use(store);
app.use(Vue3Toastify, {
    autoClose: 3000,
});

app.mount("#app");