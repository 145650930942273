<template>
  <div class="home-view">
    <div class="hero-section"><h1>Fast and Secure File Sharing</h1>
      <p>A reliable file transfer service so you can easily share your files with others </p>
      <p class="highlight">Support for files up to <strong>250GB</strong> with <strong>no format restrictions</strong>.
      </p>
    </div>

    <div class="no-signup-section">
      <p><strong>No Signups Required</strong></p>
    </div>

    <div class="upload-section" ref="uploadSection">
      <div class="upload-component">
        <h3>Try it, upload a file</h3>
        <FileUploadComponent/>
      </div>
    </div>

    <div class="feature-cards">
      <div class="card">
        <img src="@/assets/icons8-send-file-96.png" alt="File Sharing Icon"/>
        <h3>File Sharing</h3>
        <p>Temporary drag and drop file sharing.</p>
      </div>
      <div class="card">
        <img src="@/assets/icons8-add-link-96.png" alt="Short Links Icon"/>
        <h3>Short Links (Coming Soon)</h3>
        <p>A standard link shortener with analytics.</p>
      </div>
      <div class="card">
        <img src="@/assets/icons8-paste-as-text-96.png" alt="Paste Bin Icon"/>
        <h3>Paste Bin (Coming Soon)</h3>
        <p>A copy and paste clipboard.</p>
      </div>
    </div>

    <footer>
      <div class="footer-links">
        <router-link :to="{ name: 'About'}">About</router-link>
        <router-link :to="{ name: 'TermsOfService' }">Terms of Service</router-link>
        <router-link :to="{ name: 'Profile' }">Profile</router-link>
        <router-link to="/admin">Admin</router-link>
      </div>
      <div class="logo">
        <img :src="logo" alt="Logo"/>
      </div>
    </footer>
  </div>
</template>
<script>
import FileUploadComponent from '@/components/FileUploadComponent.vue';
import logo from '/public/logo.png';


export default {
  name: 'HomeView', components: {FileUploadComponent,}, methods: {
    scrollToUpload() {
      this.$refs.uploadSection.scrollIntoView({behavior: 'smooth'});
    },
  }, data() {
    return {
      logo: logo,
    };
  },
}; </script>

<style scoped>
.home-view {
  //max-width: 1200px;
  //margin: 0 auto;
  //padding: 20px;
}

.hero-section {
  text-align: center;
  margin-bottom: 60px;
}

.hero-section h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 24px;
  margin-bottom: 40px;
}

.upload-section {
  margin-bottom: 60px;
}

.upload-component h3 {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: -5px;
}

.upload-component {
  border-radius: 20px;
  padding: 20px;
  background-color: #0c77f4;
  //margin-bottom: 24px;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
}

.card {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card img {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.card h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.card p {
  font-size: 16px;
  color: #666;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #ccc;
}

.footer-links a {
  margin-right: 20px;
  color: #666;
  text-decoration: none;
}

.footer-links a:hover {
  color: #333;
}

.logo img {
  width: 100px;
}

.no-signup-section {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  margin-bottom: 40px;
  border-radius: 8px;
}

.no-signup-section strong {
  color: #007bff;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .feature-cards {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  .card:last-child {
    grid-column: 1 / -1;
    justify-self: center;
  }
}
</style>