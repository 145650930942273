<template>
  <div class="">
    <div class="content-wrapper">
      <h2 class="my-4">Admin File List</h2>

      <!-- Loading notice -->
      <div v-if="isLoading" class="alert alert-info">
        Loading files, please wait...
      </div>

      <!-- Error message -->
      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>

      <!-- File List -->
      <div v-if="files.length > 0 && !isLoading">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Name</th>
              <th class="d-none d-md-table-cell">Description</th>
              <th>File ID</th>
              <th class="d-none d-md-table-cell">Size</th>
              <th class="d-none d-md-table-cell">Content Type</th>
              <th class="d-none d-md-table-cell">Uploaded</th>
              <th class="d-none d-md-table-cell">Expires</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="file in files" :key="file.file_id">
              <td>
                <div class="file-name-cell">
                  <span class="file-name" :title="file.name">{{ file.name }}</span>
                </div>
              </td>
              <td class="d-none d-md-table-cell">{{ file.desc }}</td>
              <td>{{ file.file_id }}</td>
              <td class="d-none d-md-table-cell">{{ formatFileSize(file.size_bytes) }}</td>
              <td class="d-none d-md-table-cell">{{ file.content_type }}</td>
              <td class="d-none d-md-table-cell">{{ formatTimestamp(file.uploaded) }}</td>
              <td class="d-none d-md-table-cell">{{ formatTimestamp(file.expires) }}</td>
              <td>
                <div class="btn-group btn-group-sm">
                  <button class="btn btn-primary" @click="viewDetails(file.file_id)">
                    <i class="bi bi-eye"></i>
                  </button>
                  <button class="btn btn-danger" @click="confirmDelete(file.file_id)">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Confirmation Modal -->
      <div id="deleteModal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Confirm Deletion</h5>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete this file?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeConfirmationModal">Cancel</button>
              <button type="button" class="btn btn-danger" @click="deleteFileConfirmed">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {Modal} from 'bootstrap';

export default {
  name: 'AdminFileListView',
  data() {
    return {
      modal: null,
      selectedFileId: null,
    };
  },
  computed: {
    ...mapGetters('files', ['stateFiles', 'stateIsLoading', 'stateFileError']),
    files() {
      return this.stateFiles || [];
    },
    isLoading() {
      return this.stateIsLoading;
    },
    error() {
      return this.stateFileError;
    },
  },
  created() {
    this.fetchFiles();
  },
  methods: {
    ...mapActions('files', ['getFiles', 'deleteFile']),
    fetchFiles() {
      this.getFiles();
    },
    formatFileSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';

      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      const fileSize = (bytes / Math.pow(1024, i)).toFixed(2); // Keep two decimal places

      return fileSize + ' ' + sizes[i];
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString(undefined) + ' ' + date.toLocaleTimeString(undefined);
    },
    viewDetails(fileId) {
      this.$router.push({name: 'FileDetails', params: {id: fileId}});
    },
    confirmDelete(fileId) {
      this.selectedFileId = fileId;
      this.modal = new Modal(document.getElementById('deleteModal'));
      this.modal.show();
    },
    closeConfirmationModal() {
      this.modal.hide();
    },
    async deleteFileConfirmed() {
      try {
        await this.deleteFile(this.selectedFileId);
        this.fetchFiles(); // Refresh the file list after deletion
      } catch (error) {
        console.error('Error deleting file:', error);
        // Handle error
      }
      this.closeConfirmationModal();
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  //max-width: 1200px;
  margin: 0 auto;
}

.btn-group {
  display: flex;
}

.file-name-cell {
  max-width: 200px; /* Adjust this value as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-name {
  cursor: help;
}

@media (max-width: 767.98px) {
  .d-none.d-md-table-cell {
    display: none !important;
  }
}
</style>