<!-- LoginPage.vue -->
<template>
  <div class="login-page">
    <div class="login-container">
      <div class="form-tabs">
        <div class="tab" :class="{ active: activeTab === 'login' }" @click="activeTab = 'login'">Login</div>
        <div class="tab" :class="{ active: activeTab === 'signup' }" @click="activeTab = 'signup'">Sign Up</div>
      </div>
      <div class="form-content">
        <div v-if="activeTab === 'login'" class="login-form">
          <h2>Login</h2>
          <form @submit.prevent="login">
            <div class="form-group">
              <label for="username">Email:</label>
              <input type="text" id="email" v-model="email" required :disabled="loading">
            </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <input type="password" id="password" v-model="password" required :disabled="loading">
            </div>
            <div class="form-group">
              <router-link :to="{name: 'ForgetPassword'}" >Forgot password?</router-link>
            </div>
            <button type="submit" :disabled="loading" class="login-button">
              <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ loading ? 'Logging in...' : 'Login' }}
            </button>
          </form>
          <p v-if="error" class="error-message">{{ error }}</p>
        </div>
        <div v-if="activeTab === 'signup'" class="signup-form">
          <h2>Sign Up</h2>
          <form @submit.prevent="signup">
            <div class="form-group">
              <label for="signup-username">Email:</label>
              <input type="text" id="signup-email" v-model="signupForm.email" required :disabled="loading">
            </div>
            <div class="form-group">
              <label for="signup-password">Password:</label>
              <input type="password" id="signup-password" v-model="signupForm.password" required :disabled="loading">
            </div>
            <div class="form-group">
              <label for="signup-confirm-password">Confirm Password:</label>
              <input type="password" id="signup-confirm-password" v-model="signupForm.confirmPassword" required
                     :disabled="loading">
            </div>
            <div class="form-group terms-group">
              <input type="checkbox" id="signup-terms" v-model="signupForm.agreeToTerms" required :disabled="loading">
              <label for="signup-terms">I agree to the terms and conditions</label>
            </div>
            <button type="submit" :disabled="loading" class="signup-button">
              <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ loading ? 'Signing up...' : 'Sign Up' }}
            </button>
          </form>
          <p v-if="error" class="error-message">{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {gsap} from 'gsap';

export default {
  data() {
    return {
      activeTab: 'login',
      email: '',
      password: '',
      signupForm: {
        email: '',
        password: '',
        confirmPassword: '',
        agreeToTerms: false,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'getErrorMessage', 'isLoading']),
    error() {
      return this.getErrorMessage;
    },
    loading() {
      return this.isLoading;
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push('/profile');
    }
  },
  methods: {
    ...mapActions('auth', ['loginUser', 'signupUser']),
    async login() {
      try {
        const result = await this.loginUser({email: this.email, password: this.password});
        if(result === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
          console.log('Password reset required - redirecting to reset page');
          this.$router.push({ name: 'PasswordReset', params: { resetMode: 'forced' } });
        }
        else if(result === "CONFIRM_SIGN_UP") {
          console.log('User not confirmed - redirecting to confirm page');
          this.$router.push({ name: 'ConfirmSignup' });
        }
        else if (result) {
          console.log('Login complete - redirecting to profile');
          const redirectPath = this.$route.query.redirect;
          if (redirectPath) {
            this.$router.push(redirectPath);
          } else {
            this.$router.push('/profile');
          }
        }
      } catch (error) {
        console.error('Login error:', error);
      }
    },
    async signup() {
      try {
        const result = await this.signupUser(this.signupForm);
        if (result === "CONFIRM_SIGN_UP") {
          console.log('Signup started, need to confirm user now - redirecting to confirmation page');
          this.$router.push({name: 'ConfirmSignup'});
        }

      } catch (error) {
        console.error('Signup error:', error);
      }
    },
    animateInput(event) {
      const inputElement = event.target;
      const animationElement = inputElement.nextElementSibling;
      gsap.to(animationElement, {
        duration: 0.3,
        scaleX: 1,
        transformOrigin: 'left',
        ease: 'power1.out',
      });
    },
  },
  // watch: {
  //   activeTab(newTab, oldTab) {
  //     const formContent = this.$el.querySelector('.form-content');
  //     if (newTab === 'login') {
  //       gsap.fromTo(formContent, {x: oldTab === 'signup' ? '100%' : '-100%'}, {
  //         x: '0%',
  //         duration: 0.5,
  //         ease: 'power2.out'
  //       });
  //     } else if (newTab === 'signup') {
  //       gsap.fromTo(formContent, {x: oldTab === 'login' ? '-100%' : '100%'}, {
  //         x: '0%',
  //         duration: 0.5,
  //         ease: 'power2.out'
  //       });
  //     }
  //   },
  // },
};
</script>


<style scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.login-container {
  max-width: 400px;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 4px 4px 0 0;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: #007bff;
  color: #ffffff;
}

.form-content {
  position: relative;
  overflow: hidden;
}

.login-form,
.signup-form {
  width: 100%;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.login-button,
.signup-button {
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.login-button:before,
.signup-button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease, height 0.3s ease;
}

.login-button:hover:before,
.signup-button:hover:before {
  width: 200px;
  height: 200px;
}

.signup-button {
  margin-top: 20px;
}


.terms-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.terms-group input[type="checkbox"] {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #ccc;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.terms-group input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.terms-group input[type="checkbox"]:checked::before {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 16px;
}

.terms-group label {
  font-size: 14px;
  font-weight: normal;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}

.terms-group label:hover {
  color: #007bff;
}
</style>