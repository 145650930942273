<template>
  <div class="auth-page">
    <div class="auth-container">
      <h2>Change Password</h2>
      <form @submit.prevent="changePasswordHandler" v-if="!showSuccessMessage">
        <div class="form-group">
          <label for="old-password">Current Password:</label>
          <input type="password" id="old-password" v-model="oldPassword" required :disabled="loading" />
        </div>
        <div class="form-group">
          <label for="new-password">New Password:</label>
          <input type="password" id="new-password" v-model="newPassword" required :disabled="loading" />
        </div>
        <div class="form-group">
          <label for="confirm-password">Confirm New Password:</label>
          <input type="password" id="confirm-password" v-model="confirmPassword" required :disabled="loading" />
        </div>
        <button type="submit" :disabled="loading" class="auth-button">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ loading ? 'Changing...' : 'Change Password' }}
        </button>
      </form>
      <p v-if="error" class="error-message">{{ error }}</p>
      <div v-if="showSuccessMessage" class="success-message">
        <div class="success-icon">
          <i class="bi bi-check-circle-fill"></i>
        </div>
        <div class="success-text">
          Password changed successfully!
        </div>
        <div class="redirect-message">
          Redirecting to login in {{ redirectCountdown }} seconds...
        </div>
        <div class="redirect-progress">
          <div class="progress-bar" role="progressbar" :style="{ width: redirectProgress + '%' }"
               :aria-valuenow="redirectProgress" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import '@/assets/auth-styles.css';

export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      showSuccessMessage: false,
      redirectCountdown: 3,
      redirectProgress: 0,
      redirectTimer: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['getErrorMessage', 'isLoading']),
    error() {
      return this.getErrorMessage;
    },
    loading() {
      return this.isLoading;
    },
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    async changePasswordHandler() {
      if (this.newPassword !== this.confirmPassword) {
        this.$store.commit('auth/SET_ERROR', 'New password and confirm password do not match');
        return;
      }
      try {
        const result = await this.changePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });
        if (result.success) {
          console.log('Password change successful');
          this.showSuccessMessage = true;
          this.startRedirectCountdown();
        }
      } catch (error) {
        console.error('Password change error:', error);
      }
    },
    startRedirectCountdown() {
      this.redirectCountdown = 3;
      this.redirectProgress = 0;

      this.redirectTimer = setInterval(() => {
        this.redirectCountdown--;
        this.redirectProgress = (3 - this.redirectCountdown) * 33.33;

        if (this.redirectCountdown <= 0) {
          clearInterval(this.redirectTimer);
          this.$router.push('/login');
        }
      }, 1000);
    },
  },
  beforeUnmount() {
    clearInterval(this.redirectTimer);
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>