<script>
import {defineComponent} from 'vue'
// import UploadComponent from '@/components/UploadComponent.vue'
// import ViewFiles from "@/components/ViewFiles.vue";
// import UploadMultipartComponent from "@/components/UploadMultipartComponent.vue";
import FileUploadComponent from "@/components/FileUploadComponent.vue";

export default defineComponent({
  name: "FileUploadView",
  components: {
    // UploadComponent,
    // UploadMultipartComponent,
    // ViewFiles,
    FileUploadComponent
  },
  data() {
    return {
      form: {
        title: '',
        content: '',
      },
      errors: [],
    };
  },
});
</script>

<template>
  <section>
    <h1>Upload a file</h1>
    <hr/>
    <p>Select a file to upload. All types are permitted.</p>
    <br>
    <FileUploadComponent/>
    <br>
  </section>
</template>

<style scoped>

</style>