<template>
  <div class="loading-page">
    <h1>Loading...</h1>
    <p>Please wait while we check your authentication status.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoadingPage',
  computed: {
    ...mapGetters('auth', ['isLoading', 'isAuthenticated']),
  },
  watch: {
    isLoading(newValue) {
      console.log('isLoading changed:', newValue);
      this.redirectToIntendedRoute();
    },
  },
  mounted: function() {
    console.log('LoadingPage mounted');
    if(!this.isLoading) {
      console.log('isLoading is false, when mounted');
      this.redirectToIntendedRoute();
    }
  },
  methods: {
    redirectToIntendedRoute() {
      if (this.isAuthenticated) {
        // User is authenticated, navigate to the intended route
        const redirectRoute = this.$route.query.redirect || '/';
        this.$router.replace(redirectRoute);
      } else {
        // User is not authenticated, navigate to the 'NotAuthorized' route
        this.$router.replace({ name: 'NotAuthorized', query: { redirect: this.$route.query.redirect }});
      }
    },
  },
};
</script>

<style scoped>
.loading-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>