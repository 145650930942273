<template>
  <div class="terms-of-service">
    <header>
      <h1>Terms of Service</h1>
    </header>

    <main>
      <div class="content">
        <p v-for="paragraph in termsOfService" :key="paragraph">{{ paragraph }}</p>
      </div>
    </main>

    <footer>
      <p>&copy; {{ currentYear }} Linker.To All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
      termsOfService: `Terms of Service

        Last updated: March 26, 2024

        Welcome to Linker.To, a file-sharing platform designed to enable users to upload, share, and access files in a
        secure and efficient manner. By using our service, you agree to be bound by the following terms and conditions.
        Please read them carefully before using our services.
        Acceptance of Terms

        By accessing or using Linker.To, you agree to be bound by these Terms of Service ("ToS"), all applicable laws
        and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not
        agree with any of these terms, you are prohibited from using or accessing this site.
        Changes to Terms

        Linker.To reserves the right, at its sole discretion, to modify or replace these ToS at any time. If a revision
        is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
        Use License

        Permission is granted to temporarily download one copy of the materials (information or software) on Linker.To's
        website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of
        title, and under this license, you may not:
        modify or copy the materials;
        use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
        attempt to decompile or reverse engineer any software contained on Linker.To's website;
        remove any copyright or other proprietary notations from the materials; or
        transfer the materials to another person or "mirror" the materials on any other server.

        This license shall automatically terminate if you violate any of these restrictions and may be terminated by
        Linker.To at any time.

        User Content

        You retain all rights to any content you submit, post or display on or through Linker.To. By submitting,
        posting, or displaying content on or through Linker.To, you grant us a worldwide, non-exclusive, royalty-free
        license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit,
        display, and distribute such content in any and all media or distribution methods now known or later developed.

        You are responsible for your use of the Services and for any content you provide, including compliance with
        applicable laws, rules, and regulations. You should only provide content that you are comfortable sharing with
        others.

        No Warranty

        THE SERVICES AND ALL INCLUDED CONTENT ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, WHETHER
        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, OR NON-INFRINGEMENT. Linker.To MAKES NO WARRANTY THAT THE SERVICES WILL MEET YOUR
        REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS.
        Limitation of Liability

        IN NO EVENT SHALL Linker.To, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE FOR ANY DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
        ACCESS TO AND USE OF OUR SERVICES, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
        ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN.
        Governing Law

        These terms and conditions are governed by and construed in accordance with the laws of The United States of
        America and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
        Contact Us

        If you have any questions about these Terms, please contact us at legal@linker.to.
        `.replace(/ {2,}/g, '\n').split('\n\n'),
    };
  },
};
</script>

<style scoped>
.terms-of-service {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

header {
  text-align: center;
  margin-bottom: 40px;
}

h1 {
  font-size: 32px;
  color: #333;
}

.content {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.6;
}

footer {
  margin-top: 60px;
  text-align: center;
  color: #777;
  font-size: 14px;
}
</style>