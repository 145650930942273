<template>
  <div class="container">
    <div class="content-wrapper">
      <!-- Loading notice -->
      <div v-if="isLoading" class="alert alert-info">
        Loading file details, please wait...
      </div>

      <!-- Error message -->
      <div v-if="fileError" class="alert alert-danger">
        {{ fileError }}
      </div>

      <!-- File Details -->
      <div v-if="file && !isLoading">
        <h2 class="file-header">{{ file.name }}</h2>
        <dl class="metadata-list">
          <div class="row metadata-item">
            <dt class="col-sm-3"><span class="icon">📝</span> Description:</dt>
            <dd class="col-sm-9">{{ file.desc }}</dd>
          </div>

          <div class="row metadata-item">
            <dt class="col-sm-3"><span class="icon">🆔</span> File ID:</dt>
            <dd class="col-sm-9"><code>{{ file.file_id }}</code></dd>
          </div>


          <div class="row metadata-item">
            <dt class="col-sm-3"><span class="icon">💾</span> File Size:</dt>
            <dd class="col-sm-9"><code>{{ formatFileSize(file.size_bytes) }}</code></dd>
          </div>

          <div class="row metadata-item">
            <dt class="col-sm-3"><span class="icon">🔗</span> Content Type:</dt>
            <dd class="col-sm-9"><code>{{ file.content_type }}</code></dd>
          </div>

          <div class="row metadata-item">
            <dt class="col-sm-3"><span class="icon">📅</span> Uploaded:</dt>
            <dd class="col-sm-9">{{ formatTimestamp(file.uploaded) }}</dd>
          </div>

          <div class="row metadata-item">
            <dt class="col-sm-3"><span class="icon">⏰</span> Expires:</dt>
            <dd class="col-sm-9">{{ formatTimestamp(file.expires) }}</dd>
          </div>
        </dl>

        <!-- Share Link -->
        <div class="input-group mt-3">
          <input class="form-control" type="text" v-model="currentUrl" readonly/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" ref="copyButton" @click="copyToClipboard">
              <i class="bi bi-clipboard-fill"></i> {{ copyButtonText }}
            </button>
          </div>
        </div>

        <!-- Form for sharing the file link by email -->
        <div class="mt-3">
          <div class="input-group">
            <input v-model="email" class="form-control" type="email" autocomplete="email"
                   placeholder="Email address to share with">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" @click="shareLink">
                <i class="bi bi-share-fill"></i>
                Share Link
              </button>
            </div>
          </div>
          <p v-if="emailError" class="error-message">{{ emailError }}</p>
        </div>

        <!-- Download, Open in new tab and Delete buttons -->
        <div class="button-group mt-3">
          <a @click="handleDownloadClick" class="btn btn-primary ">
            <i class="bi bi-download"></i> Download
          </a>

          <a v-if="isVideo || isImage" @click="handlePreviewClick" class="btn btn-secondary">
            <i class="bi bi-box-arrow-up-right"></i> Preview
          </a>

          <a @click="confirmDelete" class="btn btn-danger float-right">
            <i class="bi bi-trash"></i> Delete
          </a>
        </div>
      </div>

      <!-- Confirmation Modal -->
      <div id="deleteModal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Confirm Deletion</h5>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to delete this file?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeConfirmationModal">Cancel</button>
              <button type="button" class="btn btn-danger" @click="deleteFileConfirmed">Delete</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Preview Popup -->
      <div v-if="file != null && !isLoading" id="previewModal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ file.name }}</h5>
              <button type="button" class="btn-close" @click="closePreviewModal"></button>
            </div>
            <div class="modal-body">
              <div v-if="isImage" class="text-center">
                <img :src="file.download_presigned_url" alt="Image thumbnail" class="img-fluid"/>
              </div>
              <div v-if="isVideo" class="text-center modal-video-wrapper">
                <video controls>
                  <source :src="file.download_presigned_url">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from 'vue';
import {mapActions, mapGetters} from 'vuex';
import {Modal} from 'bootstrap';

export default defineComponent({
  name: 'File-Details-View',
  data() {
    return {
      currentUrl: window.location.href,
      clipboard: null,
      copyButtonText: 'Copy Link',
      modal: null,
    };
  },
  created() {
    this.$store.dispatch('files/clearFile');
    try {
      this.$store.dispatch('files/getFile', this.$route.params.id);
    } catch (err) {
      console.log(err);
      this.errors.push("Failed to load file.");
    }
  },
  setup() {
    return {
      email: ref(''),
      emailError: ref(''),
    };
  },
  computed: {
    ...mapGetters('files', ['stateFile', 'stateIsLoading', 'stateFileError']),
    file() {
      return this.stateFile;
    },
    isLoading() {
      return this.stateIsLoading;
    },
    fileError() {
      return this.stateFileError;
    },
    isImage() {
      return this.file && this.file.content_type.startsWith('image/');
    },
    isVideo() {
      return this.file && this.file.content_type.startsWith('video/');
    },
  },
  methods: {
    ...mapActions('files', ['deleteFile', 'shareFileByEmail']),
    handleDownloadClick() {
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = this.file.download_presigned_url;
      link.setAttribute('download', this.file.name);
      // Append to the DOM
      document.body.appendChild(link);
      // Trigger the click event
      link.click();
      // Remove the element from the DOM
      document.body.removeChild(link);
      console.log('Downloaded file')
    },
    handleOpenInNewTabClick() {
      window.open(this.file.download_presigned_url, '_blank');
    },
    confirmDelete() {
      this.modal = new Modal(document.getElementById('deleteModal'));
      this.modal.show();
    },
    closeConfirmationModal() {
      this.modal.hide();
    },
    handlePreviewClick() {
      this.modal = new Modal(document.getElementById('previewModal'));
      this.modal.show();
    },
    closePreviewModal() {
      this.modal.hide();
    },
    async deleteFileConfirmed() {
      let file_id = this.file.file_id.toString();
      let result = await this.deleteFile(file_id);
      if (result) {
        setTimeout(() => this.$router.push({name: 'FileUpload'}), 2000);
      }
      this.closeConfirmationModal();
    },
    copyToClipboard() {
      if (!this.currentUrl) {
        console.error('No URL to copy');
        return;
      }
      navigator.clipboard.writeText(this.currentUrl)
          .then(() => {
            console.log('Text copied to clipboard:', this.currentUrl);
            this.copyButtonText = 'Copied!'; // Change button text on success
            setTimeout(() => this.copyButtonText = 'Copy Link', 2000); // Revert after 2 seconds
          })
          .catch(err => {
            console.error('Error copying text: ', err);
            // You can show an error message to the user here
          });
    },
    isValidEmail(emailAddress) {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regex.test(emailAddress);
    },
    shareLink() {
      if (!this.isValidEmail(this.email)) {
        this.emailError = 'Please enter a valid email address';
        return;
      }
      this.emailError = ''; // Clear any previous error message
      let file_id = this.file.file_id.toString();
      let email = this.email;
      this.shareFileByEmail({file_id, email});
    },
    formatFileSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';

      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      const fileSize = (bytes / Math.pow(1024, i)).toFixed(2); // Keep two decimal places

      return fileSize + ' ' + sizes[i];
    },
    // format in user local time
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString(undefined) + ' ' + date.toLocaleTimeString(undefined);
    },
  },
});
</script>

<style scoped>
main {
  margin-top: 2rem;
}

.content-wrapper {
  max-width: 650px;
  margin: 0 auto;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.float-right {
  margin-left: auto;
}

.file-header {
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  font-weight: 600;
  color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 0.5rem;
}

.file-thumbnail a {
  display: inline-block;
  cursor: pointer;
}

.metadata-list .metadata-item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}

dd {
  margin-bottom: 0;
}

.modal-video-wrapper {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
}

.modal-video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>