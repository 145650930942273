<template>
  <div>
    <section>
      <div v-for="error in errors" :key="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>

      <h1>Tag Details</h1>
      <hr/>

      <div v-if="loadingFromApi">
        <div class="alert alert-info" role="alert">Loading...</div>
      </div>

      <div v-else-if="tag != null">
        <div class="container mb-3">
          <div class="card mt-4">
            <div class="card-header">
              <h2 class="card-title">Tag Details - {{ this.id }}</h2>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <img v-if="tag.img" :src="tag.img" alt="Tag Image" class="img-fluid">
                  <img v-else alt="Tag Image" class="img-fluid" src="https://via.placeholder.com/300">
                </div>
                <div class="col-md-6">
                  <h3 class="card-title">{{ tag.name }}</h3>
                  <p class="card-text">{{ tag.desc }}</p>
                  <p class="card-text">Owned by: {{ tag.owner_id }}</p>
                  <p class="card-text">Created at: {{ tag.created }}</p>
                  <p class="card-text">Last Modified at: {{ tag.last_mod }}</p>
                  <p v-if="tag.redirect_flag" class="card-text">
                    Redirects to: <a :href="tag.redirect_to">{{ tag.redirect_to }}</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-footer text-muted">
              Tag ID: {{ tag.tag_id }}
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-end">
          <router-link :to="{name: 'TagPublicShortLink', params: {id: this.id}}" class="btn btn-primary">Preview Public
            Version
          </router-link>
        </div>
      </div>

      <div v-else-if="!loadingFromApi">
        <div class="alert alert-danger" role="alert">
          Tag ID <code>{{ this.id }}</code> not found
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
  name: 'Tag-Details-View',
  data() {
    return {
      form: {
        title: '',
        content: '',

      },
      id: this.$route.params.id,
      errors: [],
      loadingFromApi: true,
    };
  },
  created: function () {
    return this.$store.dispatch('viewTag', this.id)
        .catch((err => {
          if (err.response) {
            console.log("Response Error: " + err);
            console.log(err.response.data);
            console.log(err.response.status);
          } else if (err.request) {
            console.log("Request Error: " + err);
            this.errors.push("Failed to make request to API");
          } else {
            console.log("Other Error: " + err);
            this.errors.push(err);
          }
        }))
        .finally(() => {
          this.loadingFromApi = false;
        });
  },
  computed: {
    ...
        mapGetters({tag: 'stateTag'}),
  }
  ,
  methods: {}
  ,
})
;
</script>
