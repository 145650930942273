<template>
  <div class="confirm-sign-up">
    <h2>Confirm Sign Up</h2>
    <p class="email">{{ email }}</p>
    <p>Please enter the confirmation code sent to your email:</p>
    <form @submit.prevent="confirmSignUpHandler">
      <div class="form-group">
        <label for="confirmationCode">Confirmation Code</label>
        <input type="text" id="confirmationCode" v-model="confirmationCode" required/>
      </div>
      <button type="submit" :disabled="loading">Confirm</button>
      <button type="button" class="resend-button" :disabled="resendDisabled" @click="resendConfirmationCode">
        Resend Code
      </button>
    </form>
    <p v-if="email == null" class="error-message">Email address is not set - please login first</p>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    <p v-if="resendCountdown > 0" class="countdown">Resend code available in {{ resendCountdown }} seconds</p>
    <div v-if="showSuccessMessage" class="alert alert-success mt-3">
      <div class="success-message">
        <i class="bi bi-check-circle-fill"></i> Account confirmed successfully!
      </div>
      <div class="redirect-message">
        You may now login with your account in {{ redirectCountdown }} seconds...
      </div>
      <div class="progress redirect-progress">
        <div class="progress-bar" role="progressbar" :style="{ width: redirectProgress + '%' }"
             :aria-valuenow="redirectProgress" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      confirmationCode: '',
      resendDisabled: false,
      showSuccessMessage: false,
      resendCountdown: 90,
      redirectCountdown: 3,
      redirectProgress: 0,
      redirectTimer: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoading', 'getErrorMessage', 'getEmail',]),
    loading() {
      return this.isLoading;
    },
    errorMessage() {
      return this.getErrorMessage;
    },
    email() {
      return this.getEmail;
    },
  },
  methods: {
    ...mapActions('auth', ['confirmSignUp', 'resendSignUpCode']),
    async confirmSignUpHandler() {
      try {
        const success = await this.confirmSignUp({
          email: this.email,
          code: this.confirmationCode,
        });
        if (success) {
          this.showSuccessMessage = true;
          this.startRedirectCountdown();
        }
      } catch (error) {
        console.error('Confirmation error:', error);
      }
    },
    async resendConfirmationCode() {
      const resendSuccess = await this.resendSignUpCode(this.email);
      if (!resendSuccess) {
        console.error('Failed to resend confirmation code');
      }
      console.log('Requesting new confirmation code');
      this.resendDisabled = true;
      this.resendCountdown = 90;
      this.startRedirectCountdown();
    },
    startRedirectCountdown() {
      this.redirectCountdown = 3;
      this.redirectProgress = 0;

      this.redirectTimer = setInterval(() => {
        this.redirectCountdown--;
        this.redirectProgress = (3 - this.redirectCountdown) * 33.33;

        if (this.redirectCountdown <= 0) {
          clearInterval(this.redirectTimer);
          this.$router.push({name: 'Login'});
        }
      }, 1000);
    },
  },
  mounted() {
    if (this.email == null) {
      this.$router.push('/login');
    }
    //this.startCountdown();
  },
  beforeUnmount() {
    clearInterval(this.redirectTimer);
  }
};
</script>

<style scoped>
.confirm-sign-up {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
}

.email {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.resend-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.resend-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.success-message {
  font-size: 18px;
  color: #155724;
  margin-bottom: 10px;
}

.redirect-message {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.redirect-progress {
  height: 10px;
  background-color: #e9ecef;
  border-radius: 8px;
  margin-bottom: 10px;
}

.redirect-progress .progress-bar {
  background-color: #28a745;
}
</style>