import axios from 'axios';

const state = {
    tags: null,
    tag: null,
};

const getters = {
    stateTags: state => state.tags,
    stateTag: state => state.tag,
};

const actions = {
    async createTag({dispatch}, tag) {
        await axios.post('tags/', tag);
        await dispatch('getTags');
    },
    async getTags({commit}) {
        console.log("get tags all");
        let response = await axios.get('tags/');
        commit('setTags', response.data);
    },
    async viewTag({commit}, id) {
        console.log("get tag data: " + id);
        let response = await axios.get(`tags/${id}`);
        console.log("get tag data: " + JSON.stringify(response.data));
        commit('setTag', response.data);
    },
    // eslint-disable-next-line no-empty-pattern
    async updateTag({}, tag) {
        await axios.patch(`tags/${tag.id}`, tag.form);
    }
    ,
    // eslint-disable-next-line no-empty-pattern
    async deleteTag({}, id) {
        await axios.delete(`tags/${id}`);
    }
};

const mutations = {
    setTags(state, tags) {
        state.tags = tags;
    },
    setTag(state, tag) {
        state.tag = tag;
    },
    addError(state, error) {
        state.errors.push(error);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
