<template>
  <div class="container">
    <div v-for="error in errors" :key="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>

    <div v-if="this.$route.params.id">
      <h1>Edit Tag</h1>
      <p>Tag ID: <code>{{ this.$route.params.id }} </code></p>
    </div>
    <div v-else>
      <h1>Create new tag</h1>
    </div>
    <hr/>

    <form @submit.prevent="checkForm">
      <div class="form-group d-flex justify-content-center flex-column align-items-center mb-3">
        <img v-if="newUploadedCroppedImage" :src="newUploadedCroppedImage" alt="Tag Image"
             class="img-thumbnail max-height mb-3">
        <img v-else-if="current_image" :src="current_image" alt="placeholder-Sticker-img"
             class="mb-3 d-flex justify-content-center flex-column align-items-center"/>
        <img v-else :src="PLACEHOLDER_IMG" alt="placeholder-Sticker-img"
             class="mb-3 d-flex justify-content-center flex-column align-items-center">
        <input id="select-img" style="display: none" type="file" @change="onImageChange">
        <div>
          <label class="btn btn-outline-secondary" for="select-img">Select Image</label>
          <label v-if="newUploadedCroppedImage" class="btn btn-outline-secondary ms-3" @click="removeImageClick">Remove
            Image</label>
        </div>
      </div>

      <!--      Tag Type Selector     -->
      <div class="form-group mb-3 d-flex justify-content-center flex-column align-items-center">
        <label for="btn-group">Tag Type</label>
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-secondary active">
            <input id="type-nothing" v-model="tag.type" checked class="form-check-input" name="tag-type"
                   type="radio" value="basic"> Basic
          </label>
          <label class="btn btn-secondary">
            <input id="type-link" v-model="tag.type" class="form-check-input" name="tag-type" type="radio"
                   value="link"> Link
          </label>
          <label class="btn btn-secondary">
            <input id="type-asset" v-model="tag.type" class="form-check-input" name="tag-type" type="radio"
                   value="asset"> Asset
          </label>
        </div>
      </div>

      <!--      Tag Name      -->
      <div class="form-group mb-3">
        <label for="name">Name</label>
        <input id="name" v-model="tag.name" class="form-control" type="text">
      </div>

      <!--      Tag Description     -->
      <div class="form-group mb-3">
        <label for="desc">Description</label>
        <textarea id="desc" v-model="tag.desc" class="form-control" rows="3"></textarea>
      </div>

      <!--      Selected Tag Type Options -->
      <div v-if="tag.type === 'link'" class="form-group mb-3">
        <label for="redirect_to">Redirect To</label>
        <input id="redirect_to" v-model="tag.redirect_to" class="form-control" placeholder="https://example.org"
               type="url">
      </div>

      <!--      Enable 'notify owner' Selector     -->
      <div class="form-group mb-3 d-flex justify-content-center flex-column align-items-center">
        <label for="btn-group">Add "Notify Owner" button to public view?</label>
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-secondary active">
            <input v-model="tag.allow_contact_owner" checked class="form-check-input" name="notifyOwner"
                   type="radio" value="true"> Enable "Notify Owner"
          </label>
          <label class="btn btn-secondary">
            <input v-model="tag.allow_contact_owner" class="form-check-input" name="notifyOwner" type="radio"
                   value="false"> Do not permit "Notify Owner"
          </label>
        </div>
      </div>

      <!-- submit form -->
      <div class="form-group d-flex justify-content-center">
        <button class="btn btn-primary" type="submit">Submit</button>
      </div>
    </form>


    <!-- Crop Popup -->
    <div id="cropModal" class="modal modal-fullscreen" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Crop Image</h5>
          </div>
          <div class="modal-body d-flex justify-content-center">
            <cropper v-if="tag.img"
                     ref="cropper"
                     :src="tag.img"
            />
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="button" @click="cropImage">Crop</button>
            <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import {Modal} from 'bootstrap';
import placeHolderImg from '@/assets/icons8-sticker-96.png'


export default {
  data() {
    return {
      tag: {
        name: null,
        desc: null,
        type: "basic", //basic | null, link, asset
        update_image: null,
        redirect_to: null,
        allow_contact_owner: true,
        notifications: null, //multiple types of notification {"sms": true}, {"email": true}
      },
      current_image: null,
      newUploadedCroppedImage: null,
      errors: [],
      modal: null,
      PLACEHOLDER_IMG: placeHolderImg
    }
  },
  setup() {
  },
  methods: {
    checkForm() {
      this.tag.update_image = (this.newUploadedCroppedImage != null)
      console.log(this.tag);
    },
    onImageChange(e) {
      const file = e.target.files[0];
      console.log(file);
      if (!file.type.includes('image/')) {
        this.errors.push('Please select an image file (png or jpg)');
        e.target.value = '';
        return;
      }
      this.tag.img = URL.createObjectURL(file);
      console.log(this.tag.img);
      this.modal = new Modal(document.getElementById('cropModal'));
      this.modal.show();
    },
    cropImage() {
      const {
        coordinates, image, visibleArea, canvas
      } = this.$refs.cropper.getResult();
      console.log(coordinates, image, visibleArea, canvas);
      this.newUploadedCroppedImage = canvas.toDataURL();
      this.modal.hide();
      this.tag.img = '';
    },
    removeImageClick() {
      this.newUploadedCroppedImage = null;
    },
  },
  components: {
    Cropper
  },
}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
}

.modal-fullscreen .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
}

.max-height {
  max-height: 30vh;
}
</style>
